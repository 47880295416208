/* global document, navigator, initMapData, initCartData, sessionStorage, window, dataLayer */
import '..';
import naja from 'naja';
import Sticky from 'sticky-js';
import rangeSlider from 'range-slider-input';
import L from 'leaflet';
import 'leaflet.markercluster';
import autoComplete from '@tarekraafat/autocomplete.js/dist/autoComplete';

import { getCookie } from '../js/cookieHandler';

import '../js/dropdown';
import '../js/filters';
import '../js/histogram';

const publicURL = (process.env.PUBLIC_URL !== null ? `${process.env.PUBLIC_URL}` : 'http://plakatov.cz/');
const API_KEY = process.env.REACT_APP_MAPY_API_KEY;

let gtmLoaded = false;

document.addEventListener('GTMload', () => {
	gtmLoaded = true;
});

const stickyMap = new Sticky('.js-sticky-map');
stickyMap.update();

const leafletMap = L.map('mapa', {
	center: L.latLng(49.7438, 15.3387),
	zoom: 7,
});

L.tileLayer(`https://api.mapy.cz/v1/maptiles/basic/256/{z}/{x}/{y}?apikey=${API_KEY}`, {
	maxZoom: 19,
	attribution: '<a href="https://api.mapy.cz/copyright" target="_blank">&copy; Seznam.cz a.s. a další</a>',
}).addTo(leafletMap);

leafletMap.attributionControl.setPrefix('');

const LogoControl = L.Control.extend({
	options: {
		position: 'bottomleft',
	},

	onAdd() {
		const container = L.DomUtil.create('div');
		const link = L.DomUtil.create('a', '', container);

		link.setAttribute('href', 'http://mapy.cz/');
		link.setAttribute('target', '_blank');
		link.innerHTML = '<img src="https://api.mapy.cz/img/api/logo.svg" />';
		L.DomEvent.disableClickPropagation(link);

		return container;
	},
});

new LogoControl().addTo(leafletMap);

const leafletMarkersLayer = L.markerClusterGroup({
	iconCreateFunction: (cluster) => {
		const markersCount = cluster.getChildCount();

		return L.divIcon({
			html: markersCount,
			className: 'cluster',
			iconSize: [55, 55],
		});
	},
	disableClusteringAtZoom: 14,
	showCoverageOnHover: false,
	spiderfyOnMaxZoom: false,
});

const markersCoords = [];
let markersInCart;
let cartDataFromPayload;

let visitedCookies = getCookie('plakatov_visited_ads');

function drawMarkers(newMapData = initMapData) {
	leafletMarkersLayer.clearLayers();

	newMapData.forEach((marker) => {
		const markerLocation = L.latLng({ lat: marker.vgsLatitude, lng: marker.vgsLongitude });

		const markerIcon = L.divIcon({
			className: 'map__marker',
			html: '<svg xmlns="http://www.w3.org/2000/svg" width="32" height="48" viewBox="0 0 32 48">'
				+ '<path fill="currentColor" d="M22 16a6 6 0 1 1-12 0 6 6 0 0 1 12 0zm-6 32c.9-1.4 1.7-2.8 2.6-4C26.4 31.5 32 22.7 32 '
				+ '15.1c0-2-.4-4-1.3-6A15.5 15.5 0 0 0 27 4.6a16.6 16.6 0 0 0-5-3.3A16 16 0 0 0 16 0c-2 0-4 .4-6 1.2a16.6 16.6 0 0 '
				+ '0-5.1	3.3 15.5 15.5 0 0 0-3.6 4.8A14 14 0 0 0 0 15.2c0 7.6 5.6 16.4 13.4 28.7L16 48z"/></svg>',
			iconSize: [32, 48],
		});

		if (markersInCart && markersInCart.includes(marker.id)) {
			markerIcon.options.className += ' map__marker--cart';
		} else if (visitedCookies && visitedCookies.includes(marker.id.toString())) {
			markerIcon.options.className += ' map__marker--visited';
		}

		if (marker.exactMatch === '0') {
			markerIcon.options.className += ' map__marker--approximate';
		}

		const markerRender = L.marker(markerLocation, { id: marker.id, icon: markerIcon });

		leafletMarkersLayer.addLayer(markerRender);

		markersCoords.push({
			vgsLatitude: markerLocation.lat,
			vgsLongitude: markerLocation.lng,
		});
	});

	leafletMarkersLayer.addTo(leafletMap);
}

function zoomToData(markers) {
	const bounds = L.latLngBounds();

	markers.forEach((marker) => {
		const latLng = [marker.vgsLatitude, marker.vgsLongitude];
		bounds.extend(latLng);
	});

	const newCenter = bounds.getCenter();
	const newZoom = leafletMap.getBoundsZoom(bounds);

	leafletMap.setView(newCenter, newZoom);
	sessionStorage.setItem('mapPrevZoom', newZoom);
}

function cardBorders() {
	const cards = document.querySelectorAll('.js-map-card');
	cards.forEach((card) => {
		if (markersInCart && markersInCart.includes(parseInt(card.dataset.id, 10))) {
			card.classList.add('product--highlight-cart');
		} else if (visitedCookies && visitedCookies.includes(card.dataset.id)) {
			card.classList.add('product--highlight-visited');
		}
	});
}

markersInCart = initCartData;
drawMarkers();
cardBorders();

let mapCardActiveId;
let mapCardActive;

function cardActive(id = mapCardActiveId) {
	const mapCard = document.querySelector(`.product[data-id="${id}"]`);
	if (mapCard) {
		mapCard.classList.add('product--highlight');
		mapCard.scrollIntoView({ behavior: 'smooth', block: 'center' });
	}
}

function cardDeactivate() {
	if (mapCardActive) mapCardActive.classList.remove('product--highlight');
}

function syncFavorite(button, reverse) {
	let isFavorite = button.classList.contains('product__favourite--active') || button.classList.contains('is-active');
	if (reverse === true) {
		isFavorite = !isFavorite;
	}
	const target = document.querySelector(button.dataset.target);
	if (target !== null) {
		if (isFavorite === true) {
			target.classList.add('product__favourite--active');
			target.classList.add('is-active');
			if (target.href.indexOf('add') !== -1) {
				target.href = target.href.replace('add', 'remove');
			}
		} else {
			target.classList.remove('product__favourite--active');
			target.classList.remove('is-active');
			if (target.href.indexOf('remove') !== -1) {
				target.href = target.href.replace('remove', 'add');
			}
		}
	}
}

const popupLayer = L.layerGroup().addTo(leafletMap);

function createPopup(marker, data) {
	const popup = L.popup()
		.setLatLng(marker.getLatLng())
		.setContent(`
	<div class="card">
		<div class="card-header">${data.headerHtml}</div>
		<div class="card-body">${data.bodyHtml}</div>
	</div>
`);

	popupLayer.clearLayers();
	popupLayer.addLayer(popup);
}

leafletMarkersLayer.on('click', (e) => {
	const marker = e.layer;

	if (marker instanceof L.Marker) {
		leafletMap.setView(marker.getLatLng(), leafletMap.getZoom());

		const { id } = marker.options;
		const dataElement = document.querySelector('.filter__container');
		let url;
		if (dataElement.dataset.terms !== '') {
			url = `${publicURL}/ad/get-ad-place/${id}?storedRequest=${dataElement.dataset.back}&terms=${dataElement.dataset.terms}`;
		} else {
			url = `${publicURL}/ad/get-ad-place/${id}?storedRequest=${dataElement.dataset.back}`;
		}

		naja.makeRequest('POST', url, null, { history: false, unique: 'cardData' }).then((data) => {
			createPopup(marker, data);

			const cardFavoriteBtn = document.querySelector('.js-card-favorite');
			if (cardFavoriteBtn !== null) {
				cardFavoriteBtn.addEventListener('click', (cardFavoriteEvent) => {
					cardFavoriteEvent.preventDefault();
					cardFavoriteBtn.classList.toggle('product__favourite--active');
					syncFavorite(cardFavoriteBtn, false);
					naja.makeRequest('POST', cardFavoriteBtn.href, null, { history: false, unique: 'cardFavorite' });
				});
			}

			const cardModalOpenBtn = document.querySelector('.js-card-modal-open');
			if (cardModalOpenBtn !== null) {
				cardModalOpenBtn.addEventListener('click', (cardFavoriteEvent) => {
					cardFavoriteEvent.preventDefault();
					naja.makeRequest('POST', cardModalOpenBtn.href, null, { history: false, unique: 'cardFavorite' });
				});
			}

			const mapCard = document.querySelector(`.product[data-id="${id}"]`);
			mapCardActive = document.querySelector('.product--highlight');
			if (mapCard) {
				cardDeactivate();
				cardActive(id);
				mapCardActiveId = id;
			}

			leafletMap.on('popupclose', () => {
				cardDeactivate();
				mapCardActiveId = '';
			});
		});
	}
});

let cardListLoader;
let cardList;
let cardListOther;
let form;
let searchLat;
let searchLon;
let searchViewport;
let searchMoved;
let searching;
let priceFrom;
let priceTo;
let searchLimit;
let otherSearchLimit;
let btnLoader;
const filterTypeSelected = [];

let suggesting = false;
let lastRequestZoomOut = false;

let barSticky;
const isFilterSticky = () => document.querySelector('.js-filter').stickyData !== undefined;

function stickyBarOpen() {
	barSticky.elements.forEach((barElement) => {
		// eslint-disable-next-line no-param-reassign
		barElement.style.top = `${barElement.dataset.marginTopAlt}px`;
	});
}

function stickyBarClose() {
	barSticky.elements.forEach((barElement) => {
		// eslint-disable-next-line no-param-reassign
		barElement.style.top = `${barElement.dataset.marginTop}px`;
	});
}

function unstickFilter() {
	const filter = document.querySelector('.js-filter');

	if (filter.stickyData !== undefined) {
		filter.stickyData.destroy();
		delete filter.stickyData;
	}

	stickyBarClose();
	filter.removeAttribute('style');
	filter.classList.remove('filter--sticky');

	window.removeEventListener('scroll', unstickFilter);
}

function stickFilter() {
	const filter = document.querySelector('.js-filter');

	if (filter.stickyData !== undefined) {
		return;
	}

	stickyBarOpen();

	filter.classList.add('filter--sticky');
	filter.style.width = `${filter.parentElement.offsetWidth}px`;

	window.addEventListener('scroll', unstickFilter, { once: true });
}

function initLoader() {
	btnLoader	= document.querySelector('.js-btn-loader');
	cardListLoader = document.querySelectorAll('.js-card-list-loader');
	cardList = document.querySelector('.js-card-list');
	cardListOther = document.querySelector('.js-card-other-list');
}

function showLoader(shouldHideCards = false) {
	initLoader();
	if (cardListLoader && cardListOther) {
		cardListLoader.forEach((loader) => {
			loader.classList.add('is-active');
		});
		if (shouldHideCards) {
			cardListOther.classList.add('is-hidden');
		}
	} else if (cardListLoader && cardList) {
		cardListLoader.forEach((loader) => {
			loader.classList.add('is-active');
		});
		if (shouldHideCards) {
			cardList.classList.add('is-hidden');
		}
	}

	if (btnLoader) {
		btnLoader.classList.add('is-loading');
		btnLoader.disabled = true;
	}
}

function hideLoader() {
	initLoader();
	if (cardListLoader && cardList) {
		cardListLoader.forEach((loader) => {
			loader.classList.remove('is-active');
		});
		cardList.classList.remove('is-hidden');
	}
	if (btnLoader) {
		btnLoader.classList.remove('is-loading');
		btnLoader.disabled = false;
	}
}

function updateMinimized() {
	const layout = document.querySelector('.js-layout-append');
	const filter = document.querySelector('.js-filter');
	if (filter.dataset.minimized === '1') {
		layout.classList.add('ad-layout__filters--minimized');
	} else {
		layout.classList.remove('ad-layout__filters--minimized');
	}
}

function reloadPinsFromPayload(payload) {
	if (!payload.filteredMapData) {
		return;
	}
	const { filteredMapData } = payload;
	drawMarkers(filteredMapData);
}

function zoomToPayload(payload) {
	if (!payload.zoom) {
		return;
	}
	const { zoom } = payload;
	if (zoom.length > 0) {
		zoomToData(zoom);
	}
}

function searchFormSubmit(zoomMap = false, reloadPins = false, keepViewport = false, isSearching = false) {
	if (keepViewport === false) {
		searchViewport.value = null;
	}
	searchMoved.value = !zoomMap;
	searching.value = isSearching;
	const filterSticky = isFilterSticky();
	showLoader(true);

	naja.uiHandler.submitForm(form, { unique: 'form' }).then((payload) => {
		const { cartData } = payload;
		cartDataFromPayload = cartData;
		if (reloadPins === true) {
			reloadPinsFromPayload(payload);
		}
		if (zoomMap === true) {
			zoomToPayload(payload);
		}
	});
	if (filterSticky) {
		setTimeout(stickFilter, 100);
	}
	if (priceTo?.value !== '') {
		if (gtmLoaded) {
			dataLayer.push({
				event: 'gaEvent',
				gaEventData: {
					eCat: 'Filter used',
					eAct: 'Cena',
					eLab: `do ${priceTo.value}Kč/měs`,
					eVal: '',
					eNonInteraction: 'false',
				},
				eventCallback() {
					dataLayer.push({ gaEventData: undefined });
				},
			});
		}
	}
	if (filterTypeSelected.length > 0) {
		if (gtmLoaded) {
			dataLayer.push({
				event: 'gaEvent',
				gaEventData: {
					eCat: 'Filter used',
					eAct: 'Typ plochy',
					eLab: filterTypeSelected.join('|'),
					eVal: '',
					eNonInteraction: 'false',
				},
				eventCallback() {
					dataLayer.push({ gaEventData: undefined });
				},
			});
		}
	}
}

function sendSortGTM(value) {
	if (gtmLoaded) {
		dataLayer.push({
			event: 'gaEvent',
			gaEventData: {
				eCat: 'Sort Used',
				eAct: value,
				eLab: '',
				eVal: '',
				eNonInteraction: 'false',
			},
			eventCallback() {
				dataLayer.push({ gaEventData: undefined });
			},
		});
	}
}

function handleMapRedraw() {
	if (!lastRequestZoomOut) {
		searchLimit.value = 24;
		otherSearchLimit.value = 24;
	}
	lastRequestZoomOut = false;

	const bounds = leafletMap.getBounds();
	const southwest = bounds.getSouthWest();
	const northeast = bounds.getNorthEast();

	const viewport = {
		lbx: southwest.lng,
		lby: southwest.lat,
		rtx: northeast.lng,
		rty: northeast.lat,
	};

	const isViewportValid = !(viewport.lbx === viewport.rtx || viewport.lby === viewport.rty);

	if (isViewportValid) {
		searchViewport.value = JSON.stringify(viewport);
	}
	if (!suggesting) {
		searchFormSubmit(false, true, true);
	}
	suggesting = false;

	sessionStorage.setItem('mapPrevCenter', `${leafletMap.getCenter().lat}, ${leafletMap.getCenter().lng}`);
	sessionStorage.setItem('mapPrevZoom', leafletMap.getZoom());
	sessionStorage.setItem('backBtnClicked', false);
	if (isViewportValid) {
		sessionStorage.setItem('mapPrevViewport', JSON.stringify(viewport));
	}

	const newVisitedCookies = getCookie('plakatov_visited_ads');
	const oldVisitedCookies = visitedCookies;
	if (newVisitedCookies.length > 0 && oldVisitedCookies.length === 0) {
		visitedCookies = newVisitedCookies;
		drawMarkers();
	} else if (newVisitedCookies.length > 0 && !newVisitedCookies.every((v, i) => v === oldVisitedCookies[i])) {
		visitedCookies = newVisitedCookies;
		drawMarkers();
	}

	if (cartDataFromPayload && typeof cartDataFromPayload !== 'undefined' && cartDataFromPayload.length > 0) {
		const newMarkersInCart = cartDataFromPayload.sort();
		const oldMarkersInCart = markersInCart.sort();
		if (!newMarkersInCart.every((v, i) => v === oldMarkersInCart[i])
			|| (newMarkersInCart.length === 0 && oldMarkersInCart.length > 0)) {
			markersInCart = newMarkersInCart;
			drawMarkers();
		}
	}
	cardBorders();
}

leafletMap.on('moveend', () => {
	handleMapRedraw();
});

leafletMap.on('zoomend', () => {
	handleMapRedraw();
});

function resetSearch() {
	const search = document.querySelector('.js-search');
	search.value = '';
	searchFormSubmit();
	zoomToData(initMapData);
}

function initStickyBar() {
	barSticky = new Sticky('.js-sticky-filters');
	barSticky.update();
}

function init() {
	updateMinimized();
	initStickyBar();
	form = document.querySelector('.js-search-form');
	searchLat = document.querySelector('.js-search-lat');
	searchLon = document.querySelector('.js-search-lon');
	searchViewport = document.querySelector('.js-search-viewport');
	searchMoved = document.querySelector('.js-search-moved');
	searching = document.querySelector('.js-searching');
	searchLimit = document.querySelector('.js-search-limit');
	otherSearchLimit = document.querySelector('.js-other-search-limit');
	priceFrom = document.querySelector('.js-search-priceFrom');
	priceTo = document.querySelector('.js-search-priceTo');
	const filterType = document.querySelectorAll('.js-filter-type');
	const dateType = document.querySelectorAll('.js-filter-date');
	const filterLocation = document.querySelectorAll('.js-filter-location');
	const statusType = document.querySelectorAll('.js-filter-status');
	const sortType = document.querySelectorAll('.js-sort-link');
	const filterRegion = document.querySelectorAll('.js-filter-region');
	const searchInput = document.querySelector('.js-search-input');
	const searchHidden = document.querySelector('.js-search');
	const adID = document.querySelector('.js-search-adId');
	const numberPattern = /^[0-9]+$/i;

	if (searchInput) {
		searchInput.addEventListener('keyup', (e) => {
			if (
				e.keyCode === 13
				&& searchInput.value.length >= 6
				&& searchInput.value.length <= 8
				&& numberPattern.test(searchInput.value)
			) {
				e.preventDefault();
				adID.value = searchInput.value;
				searchInput.value = '';
				searchFormSubmit(true, true);
			}
		});
	}

	filterRegion.forEach((region) => {
		region.addEventListener('change', (e) => {
			e.preventDefault();
			adID.value = '';
			searchInput.value = '';
			searchHidden.value = '';
			searchFormSubmit(true, true, false);
		});
	});

	filterType.forEach((type) => {
		type.addEventListener('change', (e) => {
			e.preventDefault();
			adID.value = '';
			searchFormSubmit(false, true, true);
		});
	});

	dateType.forEach((type) => {
		type.addEventListener('change', (e) => {
			e.preventDefault();
			adID.value = '';
			searchFormSubmit(false, true, true);
		});
	});

	filterLocation.forEach((type) => {
		type.addEventListener('change', (e) => {
			e.preventDefault();
			adID.value = '';
			searchFormSubmit(false, true, true);
		});
	});

	statusType.forEach((type) => {
		type.addEventListener('change', (e) => {
			e.preventDefault();
			adID.value = '';
			searchFormSubmit(false, true, true);
		});
	});

	sortType.forEach((type) => {
		type.addEventListener('click', () => {
			sendSortGTM(type.dataset.label);
		});
	});

	const showFilterButtons = document.querySelectorAll('.js-show-filters');
	const FILTERS_SHOWN = 'js-filters-shown';
	showFilterButtons.forEach((button) => {
		button.addEventListener('click', () => {
			stickFilter();

			// eslint-disable-next-line no-param-reassign
			button.style.position	= 'absolute';
			// eslint-disable-next-line no-param-reassign
			button.style.top		= 'unset';
			// eslint-disable-next-line no-param-reassign
			button.style.bottom		= '0px';
			// eslint-disable-next-line no-param-reassign
			button.style.left		= '50%';

			button.classList.add(FILTERS_SHOWN);
			button.classList.add('filter__show-btn--expanded');
		});
	});

	const priceSlider = document.querySelector('.js-range-slider');
	const bubbleFirst = document.createElement('output');
	const bubbleSecond = document.createElement('output');
	bubbleFirst.classList.add('range-slider-output');
	bubbleSecond.classList.add('range-slider-output');

	let inputFirst;
	let inputSecond;

	const bars = document.querySelectorAll('.js-bar');
	const prices = [];

	bars.forEach((bar) => {
		prices.push(bar.dataset.price);
	});

	function checkData(ranger) {
		const value = ranger.value();
		const newpriceFrom = value[0];
		const newpriceTo = value[1];

		priceFrom.value = prices[newpriceFrom];
		priceTo.value = prices[newpriceTo];
	}

	function setBubble(val, input, bubble) {
		// eslint-disable-next-line no-param-reassign
		bubble.innerHTML = `${prices[val]} Kč`;

		// eslint-disable-next-line no-param-reassign
		bubble.style.left = `calc(${input.style.left} - 28px)`;
	}

	const absoluteMin = priceSlider.getAttribute('data-min-value');
	const absoluteMax = priceSlider.getAttribute('data-max-value');

	const ranger = rangeSlider(priceSlider, {
		min: absoluteMin,
		max: absoluteMax,
		value: [
			priceFrom.value !== '' ? prices.indexOf(priceFrom.value) : absoluteMin,
			priceTo.value !== '' ? prices.indexOf(priceTo.value) : absoluteMax,
		],
		step: 1,
		rangeSlideDisabled: true,
		onInput: () => {
			const value = ranger.value();
			const newpriceFrom = value[0];
			const newpriceTo = value[1];

			setBubble(newpriceFrom, inputFirst, bubbleFirst);
			setBubble(newpriceTo, inputSecond, bubbleSecond);
			checkData(ranger);
		},
		onThumbDragEnd: () => {
			checkData(ranger);
			searchFormSubmit(false, true, true);
		},
	});

	const inputs = priceSlider.querySelectorAll('[role="slider"]');
	// eslint-disable-next-line prefer-destructuring
	inputFirst = inputs[0];
	// eslint-disable-next-line prefer-destructuring
	inputSecond = inputs[1];

	inputFirst.append(bubbleFirst);
	inputSecond.append(bubbleSecond);

	const value = ranger.value();
	const newpriceFrom = value[0];
	const newpriceTo = value[1];

	setBubble(newpriceFrom, inputFirst, bubbleFirst);
	setBubble(newpriceTo, inputSecond, bubbleSecond);
}

function checkSessionStorage() {
	if (
		typeof (sessionStorage) !== 'undefined'
		&& sessionStorage.getItem('backBtnClicked') === 'true'
		&& typeof (sessionStorage.getItem('mapPrevCenter')) !== 'undefined'
		&& sessionStorage.getItem('mapPrevCenter') !== null
		&& typeof (sessionStorage.getItem('mapPrevZoom')) !== 'undefined'
		&& sessionStorage.getItem('mapPrevViewport') !== null
		&& typeof (sessionStorage.getItem('mapPrevViewport')) !== 'undefined'
	) {
		searchViewport.value = sessionStorage.getItem('mapPrevViewport');
		const prevCenterObj = sessionStorage.getItem('mapPrevCenter').split(', ');
		const prevCenterLat = parseFloat(prevCenterObj[0]);
		const prevCenterLng = parseFloat(prevCenterObj[1]);
		const prevCenter = [prevCenterLat, prevCenterLng];
		const prevZoom = parseInt(sessionStorage.getItem('mapPrevZoom'), 10);
		leafletMap.setView(prevCenter, prevZoom);
		sessionStorage.setItem('backBtnClicked', false);
	} else if (markersCoords.length !== 0) {
		searchViewport.value = sessionStorage.getItem('mapPrevViewport');

		const bounds = L.latLngBounds();

		markersCoords.forEach((marker) => {
			const latLng = [marker.vgsLatitude, marker.vgsLongitude];
			bounds.extend(latLng);
		});

		const newCenter = bounds.getCenter();
		const newZoom = leafletMap.getBoundsZoom(bounds);
		leafletMap.setView(newCenter, newZoom);
	}
}

const geoOptions = {
	enableHighAccuracy: true,
};

function initGeo() {
	const searchType = document.querySelector('.js-search');
	const locateMe = document.querySelector('.js-locate-me');
	const searchInput = document.querySelector('.js-search-input');
	const adID = document.querySelector('.js-search-adId');

	function processData(data) {
		const { position } = data;
		const { regionalStructure } = data;
		const region = regionalStructure.find((item) => item.type === 'regional.region' && item.name.includes('kraj'));
		const district = regionalStructure.find((item) => item.type === 'regional.region' && item.name.includes('okres'));
		const city = regionalStructure.find((item) => item.type === 'regional.municipality');
		const quarter = regionalStructure.find((item) => item.type === 'regional.municipality_part');
		const street = regionalStructure.find((item) => item.type === 'regional.street');

		const dataJSON = {
			region: region ? region.name : '',
			district: district ? district.name : '',
			city: city ? city.name : '',
			quarter: quarter ? quarter.name : '',
			street: street ? street.name : '',
		};

		return {
			json: dataJSON,
			position,
		};
	}

	const getGeoPosition = async (position) => {
		try {
			const fetchData = await fetch(`https://api.mapy.cz/v1/rgeocode/?lon=${position.coords.longitude}&lat=${position.coords.latitude}&apikey=${API_KEY}`);
			const jsonData = await fetchData.json();
			const items = jsonData.items.map((item) => ({
				value: item.name,
				data: item,
			}));
			const processedData = processData(items[0].data);

			searchInput.value = items[0].value;
			searchLon.value = processedData.position.lon;
			searchLat.value = processedData.position.lat;
			searchType.value = JSON.stringify(processedData.json);

			adID.value = '';
			searchFormSubmit(true, true);
		} catch (exc) {
			console.error('Error fetching or processing geo position:', exc);
		}
	};

	if (locateMe) {
		locateMe.addEventListener('click', (e) => {
			e.preventDefault();
			navigator.geolocation.getCurrentPosition(getGeoPosition, null, geoOptions);
		});
	}

	function processSuggestData(data) {
		const processedData = processData(data);
		adID.value = '';

		const filterRegion = document.querySelectorAll('.js-filter-region');
		filterRegion.forEach((regionFilter) => {
			regionFilter.removeAttribute('checked');
		});

		searchLon.value = processedData.position.lon;
		searchLat.value = processedData.position.lat;
		searchType.value = JSON.stringify(processedData.json);

		searchFormSubmit(true, true, false, true);
		suggesting = true;
	}

	const queryCache = {};

	const getItems = async (query) => {
		if (queryCache[query]) {
			return queryCache[query];
		}

		try {
			const fetchData = await fetch(`https://api.mapy.cz/v1/suggest?lang=cs&limit=5&type=regional&type=regional.country&type=regional.region&type=regional.municipality&type=regional.municipality_part&type=regional.street&type=regional.address&apikey=${API_KEY}&query=${query}`);
			const jsonData = await fetchData.json();
			const items = jsonData.items.map((item) => ({
				value: item.name,
				data: item,
			}));

			queryCache[query] = items;

			return items;
		} catch (exc) {
			return [];
		}
	};

	if (searchInput) {
		let autoCompleteActive = false;

		// eslint-disable-next-line no-new,new-cap
		const autoCompleteSuggest = new autoComplete({
			selector: () => searchInput,
			placeHolder: 'Hledejte adresu, město nebo ID plochy',
			searchEngine: (query, record) => `<mark>${record}</mark>`,
			data: {
				keys: ['value'],
				src: async (query) => {
					const items = await getItems(query);

					if (queryCache[searchInput.value]) {
						return queryCache[searchInput.value];
					}

					return items;
				},
				cache: false,
			},
			resultItem: {
				class: 'autocomplete__item',
				element: (item, data) => {
					const itemData = data.value.data;
					const desc = document.createElement('div');

					desc.style = 'overflow: hidden; white-space: nowrap; text-overflow: ellipsis;';
					desc.innerHTML = `${itemData.label}, ${itemData.location}`;
					item.append(
						desc,
					);
				},
				highlight: false,
			},
			resultsList: {
				class: 'autocomplete__list',
				element: (list, data) => {
					// eslint-disable-next-line no-param-reassign
					list.style.maxHeight = 'max-content';
					// eslint-disable-next-line no-param-reassign
					list.style.overflow = 'hidden';

					if (!data.results.length) {
						const message = document.createElement('div');

						message.setAttribute('class', 'no_result');
						message.style = 'padding: 5px';
						message.innerHTML = `<span>Found No Results for "${data.query}"</span>`;
						list.prepend(message);
					} else {
						const logoHolder = document.createElement('div');
						const text = document.createElement('span');
						// eslint-disable-next-line no-undef
						const img = new Image();

						logoHolder.style = 'padding: 5px; display: flex; align-items: center; justify-content: end; gap: 5px; font-size: 12px;';
						text.textContent = 'Powered by';
						img.src = 'https://api.mapy.cz/img/api/logo-small.svg';
						img.style = 'width: 60px';
						logoHolder.append(text, img);
						list.append(logoHolder);
					}
				},
				noResults: true,
			},
			wrapper: false,
			events: {
				onShow: () => {
					autoCompleteActive = true;
				},
				onHide: () => {
					autoCompleteActive = false;
				},
			},
		});

		searchInput.addEventListener('selection', (event) => {
			const origData = event.detail.selection.value.data;
			searchInput.value = `${origData.name}, ${origData.location}`;

			processSuggestData(origData);
		});

		searchInput.addEventListener('click', () => {
			if (!autoCompleteActive) {
				const query = searchInput.value;
				autoCompleteSuggest.open();

				const inputEvent = new Event('input', { bubbles: true });
				searchInput.value = query;
				searchInput.dispatchEvent(inputEvent);
			}
		});

		searchInput.addEventListener('enter', () => {
			if (searchInput.value === '') {
				resetSearch();
			}
		});
	}
}

// Keep viewport when switching between various view types that hard reload page
function addViewSwitchesZoomListener(element) {
	if (typeof (sessionStorage) !== 'undefined') {
		const viewSwitches = element.querySelectorAll('.js-keep-viewport');
		viewSwitches.forEach((viewSwitch) => {
			viewSwitch.addEventListener('click', (e) => {
				e.preventDefault();
				sessionStorage.setItem('backBtnClicked', true);
				window.location = viewSwitch.href;
			});
		});
	}
}

function initFavoriteSyncButtons() {
	const favoriteSyncButtons = document.querySelectorAll('.js-favorite-sync');
	favoriteSyncButtons.forEach((button) => {
		button.removeEventListener('click', () => syncFavorite(button, true));
		button.addEventListener('click', () => syncFavorite(button, true));
	});
}

addViewSwitchesZoomListener(document);

let positionIndex = 0;

function loadMore() {
	const products = Array.from(document.querySelectorAll('.js-map-card'));
	const items = [];
	(positionIndex !== 0 ? (products.slice(positionIndex, products.length)) : (products)).forEach((product) => {
		items.push({
			name: product.dataset.name,
			id: product.dataset.id,
			price: product.dataset.price,
			brand: product.dataset.type,
			list: 'Vyhledávání',
			position: positionIndex + 1,
		});
		positionIndex += 1;
	});
	if (gtmLoaded) {
		dataLayer.push({
			event: 'gaEeImpressions',
			ecommerce: {
				currencyCode: 'CZK',
				impressions: items,
			},
		});
	}
}

let shouldLoadMore = false;

function gtmInit() {
	const loadMoreButton = document.querySelector('.js-load-more');
	if (loadMoreButton) {
		loadMoreButton.addEventListener('click', () => {
			shouldLoadMore = true;
			showLoader();
		});
	}

	const zoomOutButton = document.querySelector('.js-zoom-out');
	if (zoomOutButton) {
		zoomOutButton.addEventListener('click', () => {
			const url = zoomOutButton.dataset.url.replace('__viewport__', searchViewport.value);
			naja.makeRequest('POST', url, null, { history: false, unique: 'zoomOut' }).then((payload) => {
				lastRequestZoomOut = true;
				zoomToPayload(payload);
			});
		});
	}

	const nextPageButton = document.querySelector('.js-next-page');
	if (nextPageButton) {
		nextPageButton.addEventListener('click', () => {
			const { limit } = nextPageButton.dataset;
			searchLimit.value = limit;
			searchFormSubmit(false, true, true);
		});
	}

	const otherNextPageButton = document.querySelector('.js-other-next-page');
	if (otherNextPageButton) {
		otherNextPageButton.addEventListener('click', () => {
			const { limit } = otherNextPageButton.dataset;
			otherSearchLimit.value = limit;
			searchFormSubmit(false, true, true);
		});
	}
}

function markerAnimation() {
	const cards = document.querySelectorAll('.js-map-card');

	if (cards) {
		cards.forEach((card) => {
			card.addEventListener('mouseenter', () => {
				const mapMarker = document.querySelector(`.map__marker[title="${card.dataset.id}"]`);
				if (mapMarker) {
					mapMarker.classList.add('is-hover');
				}
			});
			card.addEventListener('mouseleave', () => {
				const mapMarker = document.querySelector(`.map__marker[title="${card.dataset.id}"]`);
				if (mapMarker) {
					mapMarker.classList.remove('is-hover');
				}
			});
		});
	}
}

naja.snippetHandler.addEventListener('beforeUpdate', (event) => {
	const { snippet, options } = event.detail;

	if (snippet.id === 'snippet--searchForm') {
		if (isFilterSticky()) {
			unstickFilter();
			options.filterSticky = true;
		}
	}
	if (snippet.id === 'snippet--adPlaceList' || snippet.id === 'snippet--otherAdPlaceList') {
		if (shouldLoadMore) {
			options.loadMore = true;
		}
	}
});

naja.snippetHandler.addEventListener('afterUpdate', (event) => {
	const { snippet, options } = event.detail;
	if (snippet.id === 'snippet--searchForm') {
		updateMinimized();
		init();
		initGeo();

		if (options.filterSticky === true) {
			setTimeout(stickFilter, 100);
		}

		// Update all backlinks on the page with new value from search snippet
		const filterContainer = document.querySelector('.filter__container');
		const allLinks = document.querySelectorAll('a.product__img-link, a.product__link');
		allLinks.forEach((linkElement) => {
			linkElement.setAttribute('href', linkElement.href.substring(0, linkElement.href.length - 5) + filterContainer.dataset.back);
		});
	}

	if (snippet.id === 'snippet--adPlaceList' || snippet.id === 'snippet--otherAdPlaceList') {
		if (options.loadMore === true) {
			loadMore();
		}

		hideLoader();
		cardActive();
		cardBorders();
		gtmInit();
		markerAnimation();
	}

	addViewSwitchesZoomListener(snippet);
	initFavoriteSyncButtons();
});

init();
initGeo();
initLoader();
initFavoriteSyncButtons();
gtmInit();
markerAnimation();
checkSessionStorage();

document.addEventListener('GTMload', () => {
	loadMore();
	dataLayer.push({ pageType: 'search' });
});
